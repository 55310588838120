import { ExerciseQuestionType, QuestionType } from "@prisma/client"
import { QuestionDefinition } from "@sequel-care/types"
import {
    AdjustmentsHorizontalIcon,
    AlignJustifiedIcon,
    ChartLineIcon,
    Circle1Icon,
    ClockIcon,
    PercentageIcon,
    SwitchHorizontalIcon
} from "components/icons"
import {
    QuestionGrid,
    QuestionGridContext,
    RenderResponseFn,
    RenderScaleGroupFn,
    useQuestionScale
} from "components/Questionnaires/QuestionGrid"
import { useQuestionsGroupedByScale } from "components/Questionnaires/QuestionGrid/useQuestionsGroupedByScale"
import { useTranslation } from "react-i18next"
import { TbHourglass, TbListCheck, TbPhoto, TbTextSize } from "react-icons/tb"
import { useDispatch } from "react-redux"
import { useAllQuestionnaires } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { BasicQuestion } from "types/Misc"
import { getQuestionLabels } from "utils"
import DetailsScreen from "."
import { eventToTemplate } from "../utils"

const QuestionnaireDetails = ({
    questionnaireId,
    withBack,
    enableAdd = false
}: {
    questionnaireId: number
    withBack: boolean
    enableAdd?: boolean
}) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const questionnaires = useAllQuestionnaires()
    const questionnaire = questionnaires.find((q) => questionnaireId === q.id)
    const questions = useQuestionsGroupedByScale(questionnaire)

    const addToTimeline = enableAdd
        ? () => {
            dispatch(
                pushToLibraryStack({
                    view: "config",
                    eventTemplates: eventToTemplate("questionnaire", questionnaire),
                    patientId: "preserve"
                })
            )
        }
        : undefined

    return (
        <DetailsScreen
            title={questionnaire.title}
            description={questionnaire.professional_description}
            withBack={withBack}
            onAdd={addToTimeline}
            {...questionnaire}
        >
            <QuestionGridContext.Provider value={{ questionnaire }}>
                <div className="text-lg pb-6 text-dark-blue font-medium">{t("contentLib.insideQuestionnaire")}</div>
                <QuestionGrid
                    items={questions}
                    responseComponents={responseComponents}
                    RenderScaleGroup={RenderScaleGroup}
                    gridTemplateColumns="grid-cols-[2fr_3fr]"
                />
            </QuestionGridContext.Provider>
        </DetailsScreen>
    )
}

const RenderScaleGroup: RenderScaleGroupFn = ({ items }) => {
    return (
        <div className="grid grid-cols-[2fr_3fr] gap-x-8">
            <div className="flex flex-col gap-y-5 text-secondary-2">
                {items.map((item) => (
                    <div key={item.id}>{item.question}</div>
                ))}
            </div>
            <RenderScaleResponse question={items[0] as BasicQuestion} value={undefined} />
        </div>
    )
}

const questionTypeToIcon: Record<QuestionType | ExerciseQuestionType, typeof ClockIcon> = {
    minutes: TbHourglass,
    percent: PercentageIcon,
    number: Circle1Icon,
    free_text: AlignJustifiedIcon,
    guideline: AlignJustifiedIcon,
    scale: ChartLineIcon,
    scale_reverse: ChartLineIcon,
    scale_slider: AdjustmentsHorizontalIcon,
    yes_no: SwitchHorizontalIcon,
    select: TbListCheck,
    multiple_choice: TbListCheck,
    time_of_day: ClockIcon,
    text: TbTextSize,
    image: TbPhoto
}

const RenderSimpleTypeResponse: RenderResponseFn = ({ question }) => {
    const { t } = useTranslation("questionnaire")
    const Icon = questionTypeToIcon[question.type]
    return (
        <div className="text-sm flex items-center gap-4 text-black/80">
            <Icon className="text-lg" />
            {t(`questionTypes.${question.type}`)}
        </div>
    )
}

const RenderSelectResponse: RenderResponseFn = ({ question }) => {
    const { t } = useTranslation("patient")
    const options = (question.definition as QuestionDefinition)?.options
    const Icon = questionTypeToIcon[question.type]

    return (
        <div className="text-sm flex gap-4 items-start text-black/80">
            <Icon className="text-lg translate-y-0.5 shrink-0" />
            <div className="flex gap-2 flex-wrap items-end">
                {t(`contentLib.questionGrid.${question.type}`)}:
                {options?.map((option) =>
                    option ? (
                        <span key={option} className="bg-text-blue/10 text-text-blue px-1.5 rounded">
                            {option}
                        </span>
                    ) : null
                )}
            </div>
        </div>
    )
}

const RenderScaleResponse: RenderResponseFn = ({ question }) => {
    const { t } = useTranslation("patient")
    const Icon = questionTypeToIcon[question.type]

    const scale = useQuestionScale(question)
    const { min, max } = scale ?? (question.definition as { min: number; max: number })
    const labels =
        (question.definition as { labels: string[] })?.labels ?? getQuestionLabels(question as BasicQuestion, scale)

    return (
        <div className="text-sm flex gap-4 items-start sticky top-0 text-black/80">
            <Icon className="text-lg translate-y-0.25 shrink-0" />
            <div className="flex gap-2 flex-wrap items-end">
                {t("contentLib.questionGrid.scale", { min, max })}:
                {labels.map((label) =>
                    label ? (
                        <span key={label} className="bg-text-blue/10 text-text-blue px-1.5 rounded">
                            {label}
                        </span>
                    ) : null
                )}
            </div>
        </div>
    )
}

export const responseComponents: Record<QuestionType | ExerciseQuestionType, RenderResponseFn> = {
    yes_no: RenderSimpleTypeResponse,
    select: RenderSelectResponse,
    free_text: RenderSimpleTypeResponse,
    multiple_choice: RenderSelectResponse,
    number: RenderSimpleTypeResponse,
    percent: RenderSimpleTypeResponse,
    minutes: RenderSimpleTypeResponse,
    guideline: RenderSimpleTypeResponse,
    scale: RenderScaleResponse,
    scale_reverse: RenderScaleResponse,
    scale_slider: RenderScaleResponse,
    time_of_day: RenderSimpleTypeResponse,
    image: () => <div />,
    text: () => <div />
}

export default QuestionnaireDetails
