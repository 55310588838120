import { AppointmentStatus, PatientToFamilyUser, PortalNotification, TreatmentEndReason } from "@prisma/client"
import { DashboardCard, PatientFileWithQuestionnaires } from "@sequel-care/types"
import { EventsByDate, FlatEvent, GetEventsPayload } from "@sequel-care/types/Event"
import { GoalsDashboardData } from "@sequel-care/types/Goals"
import { PatientWithDiagnoses, PatientWithMetrics, TrackerManagerData } from "@sequel-care/types/Patient"
import {
    CompleteTrackerResponse,
    GetTrackerEventsPayload,
    GetTrackerQuestionsPayload,
    PatientOrOrgId,
    TrackerQuestionInput
} from "@sequel-care/types/Tracker"
import { Dictionary, Omit } from "lodash"
import { PatientDashboardRanges } from "types/Misc"
import { getPatientIdFromPath } from "utils"
import { ApiGet, ApiPost, ApiPut, SelfAbortingGet } from "./ApiRequest"

const getPatientInstance = new SelfAbortingGet<PatientFileWithQuestionnaires<string, string>>("getPatient")
export const getPatient = async (patientId: number) => {
    return getPatientInstance.run(`/patient/${patientId}`)
}

export const getAllPatients = async () => {
    const instance = new ApiGet<PatientWithDiagnoses<string, string>[]>(`/patient/all`)
    instance.callerName = getAllPatients.name
    return instance.run()
}

export const getQuestionnaireEventsInstance = new SelfAbortingGet<GetEventsPayload<string>>("getQuestionnaireEvents")
export async function getQuestionnaireEvents(start: Date, end: Date) {
    try {
        return await getQuestionnaireEventsInstance.run(`/patient/${getPatientIdFromPath()}/questionnaire-events`, {
            start,
            end
        })
    } catch (error) {
        // This is probably from an abort... Do nothing
    }
}

export const getTrackerEventsInstance = new SelfAbortingGet<GetTrackerEventsPayload<string>>("getTrackerEventsInstance")
export async function getTrackerEvents(start: Date, end: Date) {
    try {
        return await getTrackerEventsInstance.run(`/patient/${getPatientIdFromPath()}/tracker-events`, {
            start,
            end
        })
    } catch (error) {
        // This is probably from an abort... Do nothing
    }
}

export const getPatientQuestionnaireMetricsInstance = new SelfAbortingGet<Record<string, DashboardCard[]>>(
    "getPatientQuestionnaireMetrics"
)
export async function getPatientQuestionnaireMetrics(range: PatientDashboardRanges) {
    try {
        return await getPatientQuestionnaireMetricsInstance.run(
            `/patient/${getPatientIdFromPath()}/dashboard/metrics`,
            { range }
        )
    } catch (error) {
        // This is probably from an abort... Do nothing
    }
}

export const getPatientActivityInstance = new SelfAbortingGet<DashboardCard[]>("getPatientActivity")
export async function getPatientActivity(range: PatientDashboardRanges) {
    try {
        return await getPatientActivityInstance.run(`/patient/${getPatientIdFromPath()}/dashboard/activity`, { range })
    } catch (error) {
        // This is probably from an abort... Do nothing
    }
}

export const getPatientGoalsInstance = new SelfAbortingGet<GoalsDashboardData>("getPatientGoals")
export async function getPatientGoals() {
    try {
        return await getPatientGoalsInstance.run(`/goals?patient=${getPatientIdFromPath()}`)
    } catch (error) {
        // This is probably from an abort... Do nothing
    }
}

export const getPatientFeatures = async (patient_id: number) => {
    const instance = new ApiGet<{ [key: string]: boolean }>(`/patient/${patient_id}/features`)
    instance.callerName = getPatientFeatures.name
    return instance.run()
}

export const updatePatientFeatures = async (patient_id: number, featureObj: { [key: string]: boolean }) => {
    const instance = new ApiPost<{ success: boolean }>(`/patient/${patient_id}/features`, featureObj)
    instance.callerName = updatePatientFeatures.name
    return instance.run()
}

export const getAppointments = async (start: Date, end: Date) => {
    const instance = new ApiGet<{ past: FlatEvent<string>[]; upcoming: EventsByDate<string> }>(`patient/appointments`, {
        start,
        end
    })
    instance.callerName = getAppointments.name
    return instance.run()
}

export const getPatientListData = async (patientId: number) => {
    const instance = new ApiGet<PatientWithMetrics<string>>(`patient/${patientId}/list-data`)
    instance.callerName = getPatientListData.name
    return instance.run()
}

export const toggleFamilyAuth = async (patientId: number, contactId: number) => {
    const instance = new ApiPost<{ success: boolean; uid: string } | PatientToFamilyUser>(
        `patient/${patientId}/toggle-family?contact=${contactId}`
    )
    instance.callerName = toggleFamilyAuth.name
    return instance.run()
}

export const setAppointmentStatus = async (eventId: number, status: AppointmentStatus, date: string) => {
    const instance = new ApiPut<{ success: boolean }>(`event/${eventId}/appointment/status`, {
        status,
        date_on_timeline: date
    })
    instance.callerName = setAppointmentStatus.name
    return instance.run()
}

export const getTrackerSettings = async (tracker: number) => {
    const instance = new ApiGet<TrackerManagerData>(`tracker/manager`, { tracker })
    instance.callerName = getTrackerSettings.name
    return instance.run()
}

export const updateTracker = async (updatePayload: { trackerId?: number; data: TrackerManagerData }) => {
    const instance = new ApiPost<{ success: Boolean }>(`tracker/manager`, updatePayload)
    instance.callerName = updateTracker.name
    return instance.run()
}

export const createCustomMeasure = async (data: TrackerQuestionInput, id: PatientOrOrgId) => {
    const instance = new ApiPost(`tracker/questions`, { input: data, ...id })
    instance.callerName = createCustomMeasure.name
    return instance.run()
}

export const getTrackerResults = async (patientId: number, start: Date, end: Date) => {
    const instance = new ApiGet<{ responses: CompleteTrackerResponse[]; colors: Dictionary<string> }>(
        `tracker/${patientId}/results`,
        { start, end }
    )
    instance.callerName = getTrackerResults.name
    return instance.run()
}

export const getTrackerQuestions = async ({ patientId, organizationId }: Partial<PatientOrOrgId>) => {
    const instance = new ApiGet<GetTrackerQuestionsPayload>(`tracker/questions`, {
        patient: patientId,
        organization: organizationId
    })
    instance.callerName = getTrackerQuestions.name
    return instance.run()
}

export const fetchDashboardPatients = () => {
    const instance = new ApiGet<PatientWithMetrics<string>[]>("patient/dashboard")
    instance.callerName = fetchDashboardPatients.name
    return instance.run()
}

export const fetchOverviewData = (params: Dictionary<any>) => {
    const instance = new ApiPost<PortalNotification[]>(`patient/${getPatientIdFromPath()}/overview`, params)
    instance.callerName = fetchOverviewData.name
    return instance.run()
}

export const readPatientNotifications = (id?: number) => {
    const instance = new ApiPut<boolean>(`patient/${getPatientIdFromPath()}/notification-read${id ? `?id=${id}` : ""}`)
    instance.callerName = readPatientNotifications.name
    return instance.run()
}

export const endTreatment = (patient_id: number, data: { date: Date; reason: TreatmentEndReason }) => {
    const instance = new ApiPost<
        Pick<PatientFileWithQuestionnaires<string, string>, "treatment_end" | "treatment_end_reason">
    >(`patient/${patient_id}/end-treatment`, data)
    instance.callerName = endTreatment.name
    return instance.run()
}

export const getExistingFamily = (email: string, patient_id?: number) => {
    const instance = new ApiGet<any>("patient/family-exist", { email, patient_id })
    instance.callerName = getExistingFamily.name
    return instance.run()
}

export const fetchPatientResetPassword = async (id: number) => {
    const instance = new ApiPost(`patient/${id}/reset_password`)
    instance.callerName = fetchPatientResetPassword.name
    return instance.run()
}
