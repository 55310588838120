import { OrgRole } from "@prisma/client"
import { last } from "lodash"
import { useSelector } from "react-redux"
import { NotificationCategories, PatientDashboardRanges, PatientPage } from "types/Misc"
import { DashboardGraphType, PatientState, RootState } from "types/Redux"

const emptyArray = [] as []

export const useCurrentRole = () => useSelector((state: RootState) => state.global.currentRole)
export const useCurrentUser = () => useSelector((state: RootState) => state.global.user)
export const useCurrentPreferences = () => useSelector((state: RootState) => state.global.user.preferences)
export const useUsersInCurrentOrg = () => useSelector((state: RootState) => state.global.usersInCurrentOrg)
export const useFindUserById = (id: number) =>
    useSelector((state: RootState) => state.global.usersInCurrentOrg.find((user) => user.user_id === id))
export const useGlobalLoading = () => useSelector((state: RootState) => Boolean(state.global.loading.length))
export const usePatientList = () => useSelector((state: RootState) => state.global.patientList)
export const usePatientFromList = (id: number) =>
    useSelector((state: RootState) => state.global.patientList.find((patient) => patient.id === id) ?? null)
export const useUpcomingAppointments = () => useSelector((state: RootState) => state.global.appointments?.upcoming)
export const usePastAppointments = () => useSelector((state: RootState) => state.global.appointments?.past)
export const useIsChatDisabled = (patient_id: number) =>
    useSelector((state: RootState) => state.global.patientList.find(({ id }) => id === patient_id)?.chat_disabled)

export const useCurrentLanguage = () =>
    useSelector((state: RootState) => state.global.user?.preferences?.language ?? "en")
export const useWeekStart = () => useSelector((state: RootState) => state.global.user?.preferences.week_start ?? "mon")

export const useCurrentPatient = () => useSelector((state: RootState) => state.patient.currentPatient)
export const useCurrentPatientFeatures = () => useSelector((state: RootState) => state.patient.currentPatientFeatures)
export const usePatientCollaborators = () =>
    useSelector((state: RootState) => state.patient.currentPatient?.collaborators ?? emptyArray)
export const usePatientContacts = () =>
    useSelector((state: RootState) => state.patient.currentPatient?.contacts ?? emptyArray)
export const useCurrentCollaborator = () => useSelector((state: RootState) => state.patient.currentCollaborator)

export const useTimelineEvents = () => useSelector((state: RootState) => state.patient.eventsByDate)
export const useDashboardEvents = () => useSelector((state: RootState) => state.patient.dashboard.questionnaireEvents)
export const useTrackerEvents = () => useSelector((state: RootState) => state.patient.dashboard.trackerEvents)
export const useGanttEvents = () => useSelector((state: RootState) => state.patient.eventsForGantt)

export const useCommentsByEvent = (eventId: number) =>
    useSelector((state: RootState) => state.patient.comments[eventId] ?? null)
export const useComments = () => useSelector((state: RootState) => state.patient.comments ?? null)

export const useNotificationsLoading = () => useSelector((state: RootState) => state.notifications.loading)
export const useNotifications = (category: NotificationCategories) =>
    useSelector((state: RootState) => state.notifications.notifications[category])
export const useNotificationCount = (category: NotificationCategories) =>
    useSelector((state: RootState) => state.notifications.count[category])
export const useUnread = () => useSelector((state: RootState) => state.notifications.unread)

export const useAllCarePlans = () => useSelector((state: RootState) => state.global.allCarePlans)
export const useAllQuestionnaires = () => useSelector((state: RootState) => state.global.allQuestionnaires)
export const useAllAvailableQuestionnaires = () =>
    useSelector((state: RootState) => state.global.allQuestionnaires).filter(
        (questionnaire) => questionnaire.is_deleted === false
    )
export const useAllExercises = () => useSelector((state: RootState) => state.global.allExercises)
export const useQuestionnaire = (questionnaireId: number) =>
    useSelector((state: RootState) => state.global.allQuestionnaires.find(({ id }) => id === questionnaireId))
export const useExercise = (exerciseId: number) =>
    useSelector((state: RootState) => state.global.allExercises.find(({ id }) => id === exerciseId))

export const usePatientLoading = (key: PatientPage) => useSelector((state: RootState) => state.patient.loading[key])
export const usePatientDashboard = () => useSelector((state: RootState) => state.patient.dashboard)
export const useGraphByKey = (key: keyof PatientState["graphs"]) =>
    useSelector((state: RootState) => state.patient.graphs[key])
export const useDashboardCards = (graphKey: DashboardGraphType, range: PatientDashboardRanges) =>
    useSelector((state: RootState) => state.patient.dashboard.cards[graphKey][range])

export const useAllGoals = () => useSelector((state: RootState) => state.global.goals)
export const useGoalsData = () => useSelector((state: RootState) => state.patient.goals)

export const useCollaboratorManagerOpen = () =>
    useSelector((state: RootState) => state.patient.isCollaboratorManagerOpen)

export const useCurrentSidebar = () => useSelector((state: RootState) => last(state.sidebars.stack))

export const useDiagnoses = () => useSelector((state: RootState) => state.global.diagnoses)

export const usePatientTags = () => useSelector((state: RootState) => state.global.patientTags)

export const usePatientTrackerManager = () => useSelector((state: RootState) => state.patient.tracker.manager)
export const useOrganizationTrackerManager = () => useSelector((state: RootState) => state.global.tracker?.manager)
export const usePatientTrackerGroups = () => useSelector((state: RootState) => state.patient.tracker.groups)
export const useOrganizationTrackerGroups = () => useSelector((state: RootState) => state.global.tracker?.groups)

export const usePatientTrackerMeasures = () => useSelector((state: RootState) => state.patient.tracker.measures)
export const useOrganizationTrackerMeasures = () => useSelector((state: RootState) => state.global.tracker.measures)

export const useTrackerData = () => useSelector((state: RootState) => state.patient.tracker.dashboard)
export const useGlobalTrackerQuestions = () => useSelector((state: RootState) => state.global.tracker?.questions)
export const usePatientTrackerQuestions = () => useSelector((state: RootState) => state.patient.tracker.questions)
export const useIsExternalTherapist = () =>
    useSelector((state: RootState) => state.global.currentRole.role === OrgRole.external_therapist)
export const useSettingsLoading = () => useSelector((state: RootState) => state.global.settingsLoading)

export const useChat = () => useSelector((state: RootState) => state.global.chat)

export const useOverviewNotifications = () => useSelector((state: RootState) => state.patient.overview.notifications)

export const useOverviewEngagementMontlyData = () =>
    useSelector((state: RootState) => state.patient.overview.engagementMontly)
export const useOverviewActivity = () => useSelector((state: RootState) => state.patient.overview.activity)
export const useEventsByWeek = () => useSelector((state: RootState) => state.patient.overview.eventsByWeek)
export const useGoalData = () => useSelector((state: RootState) => state.patient.overview.goalProgress)
export const useOverviewQuestionnaireScores = () =>
    useSelector((state: RootState) => state.patient.overview.questionnairesScores)
export const useSleepData = () => useSelector((state: RootState) => state.patient.overview.sleep)
export const useOverviewTracker = () => useSelector((state: RootState) => state.patient.overview.tracker)
export const useMoodData = () => useSelector((state: RootState) => state.patient.overview.mood)
